import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import YouTubeEmbed from "../components/YouTubeEmbed"
import ToggleMute from "../components/ToggleMute"
// import { ReactVideo } from "reactjs-media"
// import GroomingVideo from "../../static/videos/Grooming_video.mp4"
// import GroomingPoster from "../../static/images/poster.png"

export default function ContractorAgreement2A() {
  const { user, saveUser, saveDraftUser } = useContext(UserContext)

  return (
    <Background container>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-5 font-weight-bold mt-4">Appendix 2</h1>
        <p className="font-weight-bold">
          I will now show you the video on grooming standard required for work.
        </p>

        <YouTubeEmbed embedId="nx1_mn-_rdQ" />

        {/* <ReactVideo
          src={GroomingVideo}
          poster={GroomingPoster}
          primaryColor="blue"
          onPlay={() => {
            user.audio && user.audio.pause && user.audio.pause()
          }}
        /> */}

        <div className="text-center" style={{ marginBottom: 100 }}>
          <p className="font-weight-bold p-4">
            By agreeing and accepting, you accept and agree to the rules and
            regulations that have been briefed to you.
            <br /> Please click "I Agree" button to confirm your acceptance and
            agreement.
          </p>
        </div>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="I Agree"
            size="medium"
            onClick={() => {
              if (user.isSoundActive) {
                user.audio && user.audio.play && user.audio.play()
              }
              saveDraftUser(user)
              navigate("/contractor-agreement-3a")
            }}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-1a")}
          />
        </div>
      </div>
    </Background>
  )
}
